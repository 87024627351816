import { IonIcon, IonInput, IonRippleEffect } from "@ionic/react";
import { search } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import "../css/search-input.css";
import SuggestionService from "../Services/SuggestionService";
import filtersControl from "./filtersControl";
export default function ({ setSuggestion, setExternalInputText }) {
  const params = new URL(window.location).searchParams;

  var [inputText, setInputText] = useState();
  var history = useHistory();

  useEffect(() => {
    var value = params.get("query");
    setInputText(value === null ? "" : value);
  }, []);

  var filters = [
    "Centers for Disease Control",
    "Cdc",
    "John Hopkins University",
    "John Hopkins",
    "John University",
    "UK news",
    "Cable News Network",
    "Cable News",
    "Cable Network",
    "Cnn",
    "Bbc",
    "South African News",
    "Zimbabwe news",
    "Zim news",
    "Aljazira",
  ]
  var f = filters.map(item => item.toLowerCase());
  function inputHandler(text = "") {
    console.log(text);
    setSuggestion([]);
    setExternalInputText(text);
    setInputText(text);
    if (text.trim().length > 2) {
      SuggestionService(text)
        .done((res) => {
          if (res.length > 0) {
            var ff = res[1].filter((ee, i) => filtersControl(ee))
            setSuggestion(ff);
          }
          else {
            setSuggestion(res.length > 1 ? res[1] : []);
          }
        })
        .catch((e) => setSuggestion([]));
    } else {
      setSuggestion([]);
    }
  }
  function submitForm() {
    var input_ = inputText;

    var url = new URL(window.location.href.replace("#/", ""));
    var path_ = "/";
    if (url.pathname.trim() == "/") {
      var page = 0;
      path_ = `all?page=${page}&query=${input_}`;
    } else {
      var param = Object.fromEntries(
        new URLSearchParams(window.location.search)
      );
      page = param.page;
      path_ = `${url.pathname}?page=${page}&query=${input_}`;
    }
    setSuggestion([]);
    history.push(path_);
  }
  return (
    <form className="search-input home-input" onSubmit={(e) => submitForm(e)}>
      <div className="input-wrapper">
        <input
          onChange={(e) => inputHandler(e.target.value)}
          color="primary"
          id="all"
          value={inputText}
        />
        <button onClick={submitForm}>
          <IonIcon icon={search} />
          <IonRippleEffect></IonRippleEffect>
        </button>
      </div>
    </form>
  );
}

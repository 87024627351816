import React, { useState, useEffect } from "react";
import axios from "axios";
import "@tomtom-international/web-sdk-maps/dist/maps.css";
// import { Card } from "primereact/card";

import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css";                                //icons

import { Heading, Link, MapView, Text, View } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Marker, Popup } from "react-map-gl";
import { BiCurrentLocation } from "react-icons/bi";
import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonText } from "@ionic/react";

const API_KEY = "lUdA4V1PtTFwficm7XmXHADSwx5vwpiA";

function Map({ searchQuery }) {
  var [userCoordinates, setUserCoordinates] = useState({});
  const [searchResults, setSearchResults] = useState([]);
  const [query, setQuery] = useState(searchQuery);

  const getNearbyPlaces = async (
    query,
    lat,
    lon,
    limit = 10,
    radius = 10000
  ) => {
    let baseUrl = "https://api.tomtom.com/search/2/search";
    let queryString = `limit=${limit}&lat=${lat}&lon=${lon}&radius=${radius}&relatedPois=all&key=${API_KEY}`;
    let response = await axios.get(`${baseUrl}/${query}.json?${queryString}`);
    // console.log(tt.services.fuzzySearch)
    return response.data.results;
  };
  const getUserLocation = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserCoordinates({
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          });
        },
        async (error) => {
          console.log(error);
        }
      );
    }
  };

  useEffect(() => {
    getUserLocation();
    console.log("SEARCH QUERY:", query);
    getNearbyPlaces(query, userCoordinates.lat, userCoordinates.lon).then(
      (res) => setSearchResults(res)
    );
  }, [query, userCoordinates.lat, userCoordinates.lon]);
  return (
    <div
      style={{
        // borderRadius: "20px",
        // backgroundColor: "#efefef88",
        // padding: "20px",
      }}
    >
      <MapView
        initialViewState={{
          latitude: userCoordinates.lat,
          longitude: userCoordinates.lon,
          zoom: 13,
        }}
        style={{
          width: "100%",
          height: "33vh",
          maxHeight: "250px",
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
          marginBottom: "20px",
        }}
      >
        {console.log(userCoordinates)}
        {/* <Marker 
          latitude={userCoordinates.lat}
          longitude={userCoordinates.lon}

        /> */}
        {/* <Marker
          latitude={userCoordinates.lat}
          longitude={userCoordinates.lon}
          children={
            <HBMarker
              number={<BiCurrentLocation size={20} color="white" />}
              color="#fe1010"
            />
          }
        /> */}
      </MapView>
      {searchResults.length
        ? searchResults.map((result, key) => {
            let distance = `${result.dist}`;
            distance = distance.substring(0, 4) / 1000;

            return (
              <IonCard  className="results_card" style={{padding:"5px"}} href={"https://" + result.poi.url}>
              <IonCardHeader>
                <IonCardTitle
                  style={{ color: "var(--ion-color-very-dark)" }}
                >
                  <b> {`${key + 1}.${result.poi.name} | ${distance} KM`}</b>
                </IonCardTitle>
              </IonCardHeader>

              <IonCardContent>
              {result.address.freeformAddress}
              </IonCardContent>

              <IonText className="linkFotter">{"https://" + result.poi.url}</IonText>
            </IonCard>
          
            );
          })
        : null}
    </div>
  );
}

export default Map;

// const MapComponent = ({ coords, results }) => {
//   return (
//     <View width="100%" height="40vh">
//       <MapView
//         initialViewState={{
//           latitude: coords.lat,
//           longitude: coords.lon,
//           zoom: 13,
//         }}
//         style={{
//           width: "100%",
//           height: "100%",
//           borderTopLeftRadius: "20px",
//           borderTopRightRadius: "20px",
//           border: ".5px solid #26badb",
//         }}
//       >
//         <Marker
//           latitude={coords.lat}
//           longitude={coords.lon}
//           children={
//             <HBMarker
//               number={<BiCurrentLocation size={20} color="white" />}
//               color="#fe1010"
//             />
//           }
//         />
//         {results.map((result, key) => {
//           return (
//             <MarkerWithPopup
//               key={key}
//               number={key + 1}
//               longitude={result.position.lon}
//               latitude={result.position.lat}
//               title={result.address.freeformAddress}
//               header={result.poi.name}
//             />
//           );
//         })}
//       </MapView>
//     </View>
//   );
// };

function MarkerWithPopup({ latitude, longitude, title, header, number }) {
  const [showPopup, setShowPopup] = useState(false);

  const handleMarkerClick = ({ originalEvent }) => {
    originalEvent.stopPropagation();
    setShowPopup(true);
  };

  return (
    <>
      <Marker
        latitude={latitude}
        longitude={longitude}
        onClick={handleMarkerClick}
        children={<HBMarker number={number} color="#26badb" />}
      />
      {showPopup && (
        <Popup
          latitude={latitude}
          longitude={longitude}
          offset={{ bottom: [0, -40] }}
          onClose={() => setShowPopup(false)}
        >
          <Heading level={5}>{header}</Heading>
          <Text>{title}</Text>
        </Popup>
      )}
    </>
  );
}

const HBMarker = ({ number, color }) => {
  return (
    <div
      style={{
        position: "relative",
        width: "30px",
        height: "30px",
      }}
    >
      <span
        style={{
          position: "absolute",
          left: 5,
          top: 2,
          zIndex: 10,
          color: "#fff",
          width: "20px",
          // border:".5px solid blue",
          display: "grid",
          placeItems: "center",
        }}
      >
        {number}
      </span>
      <span style={{ position: "absolute", top: 0, left: 0 }}>
        <svg
          width="30"
          height="30"
          viewBox="0 0 754 1058"
          fill={color}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M754 380C754 589.868 754 592.5 377 1057.5C0 590.5 0 589.868 0 380C0 170.132 168.789 0 377 0C585.211 0 754 170.132 754 380Z"
            fill={color}
            stroke="#FFA500"
            strokeWidth={40}
          />
        </svg>
      </span>
    </div>
  );
};

import { IonList } from '@ionic/react';
import React from 'react'

export default function Suggestions({ getSuggestion, path,className }) {
    return (
        <div className="suggestionDropdown" >
            {getSuggestion.length !== 0 ? (
                <>
                    <div className={className}>
                        {getSuggestion.map((item, index) => (
                            <div
                                className="suggestionItem"
                                key={index}
                                onClick={(e) => {
                                    window.location.href = `/${path}?page=0&query=${item}`
                                }}
                            >
                                {item}
                                {/* <IonLabel></IonLabel> */}
                            </div>
                        ))}
                    </div>
                </>
            ) : (
                ""
            )}
        </div>
    )
}


import React, { useEffect, useState } from 'react'
import PopOver from '../components/FloatingButton'
import {IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonPage, IonTitle, IonToggle, IonToolbar } from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import { useHistory } from 'react-router';

export default function () {

    var [isThemeActive, setIsThemeActive] = useState(false);
    useEffect(() => {

        if (localStorage.getItem("theme") !== null) {
            var theme = localStorage.getItem("theme")
            document.body.classList.add(theme)
            setIsThemeActive(true)
        }

    }, []);

    const toggleDarkModeHandler = () => {
        if (localStorage.getItem("theme") === null|| localStorage.getItem("theme") === "none-mode") {
            localStorage.setItem("theme", "dark");
            document.body.classList.toggle('dark');
            setIsThemeActive(true)
        } else {
            localStorage.setItem("theme","none-mode")
            document.body.classList.toggle('dark');
            setIsThemeActive(false)


        }
    };
     var history=useHistory()
    return (
        <>
            <IonPage>

                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonButton onClick={e=>{
                                history.goBack();
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1000);
                            }}>
                                <IonIcon  slot="icon-only" icon={arrowBack}  />
                            </IonButton>
                        </IonButtons>
                        <IonTitle >Settings</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonContent>



                    <PopOver />

                    <IonList>
                        <IonItem>
                            <IonLabel><b>Appearance</b></IonLabel>
                        </IonItem>
                        <IonItem>
                            <IonLabel>Dark mode</IonLabel>
                            <IonToggle name="darkMode"
                                checked={isThemeActive}
                                onIonChange={toggleDarkModeHandler} />
                        </IonItem>
                    </IonList>



                    <IonList>
                        <IonItem>
                            <IonLabel><b>Search Engine</b></IonLabel>
                        </IonItem>
                        <IonItem>
                            <IonLabel>Block Ads</IonLabel>
                            <IonToggle />
                        </IonItem>
                        <IonItem>
                            <IonLabel>Block Images</IonLabel>
                            <IonToggle />
                        </IonItem>
                        <IonItem>
                            <IonLabel>Block Videos</IonLabel>
                            <IonToggle />
                        </IonItem>
                    </IonList>




                    <IonList>
                        <IonItem>
                            <IonLabel><b>Advanced Settings</b></IonLabel>
                        </IonItem>
                        <IonItem>
                            <IonLabel>Zoom</IonLabel>
                            <IonToggle />
                        </IonItem>
                    </IonList>
                </IonContent>

                <br />
                <br />
                <br />
            </IonPage>
        </>
    )
}

import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonSkeletonText,
  IonSlide,
  IonSlides,
  IonText,
  IonModal,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import HomeNavbar from "../components/HomeNavbar";
import PopOver from "../components/FloatingButton";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {
  Audio,
  Bars,
  Circles,
  Hearts,
  Puff,
  Rings,
  TailSpin,
  ThreeDots,
} from "react-loader-spinner";
import {
  arrowDropright,
  arrowDroprightCircle,
  arrowDropleftCircle,
} from "ionicons/icons";

import "../css/icons-list.css";
import InputSearch from "../components/InputSearch";
import $ from "jquery";
import GetFullPath from "../components/GetFullPath";
import { book, image, search, videocam } from "ionicons/icons";
import LinksTab from "../components/LinksTab";
import Suggestions from "../components/Suggestions";
import filtersControl from "../components/filtersControl";
import { useRef } from "react";
import { ConsoleLogger } from "@aws-amplify/core";
export default function () {
  const [images, setImages] = useState([]);
  const [imagesStack, setImagesStack] = useState([]);
  const [imageShow, setImagesShow] = useState(false);
  const [imageUrl, setImagesUrl] = useState("");
  const [relatedImages, setRelatedImages] = useState([]);
  var [getSuggestion, setSuggestion] = useState([]);
  var [inputText, setInputText] = useState("");
  var [scrollCounter, setScrollCounter] = useState(0);
  var [HaveData, setHaveData] = useState(false);
  var [isLoading, setIsLoading] = useState(false);
  var counter = 0;
  const listInnerRef = useRef(null);
  var [screenSize, setScreenSize] = useState(0);
  const sliderRef = useRef(null);
  const [clickedpicture, setClickedpicture] = useState({});

  var fakeData = [];
  for (let index = 0; index < 10; index++) {
    fakeData.push(index);
  }

  function ImageContainer({ item, index, imgsArr }) {
    const [width, setWidth] = useState(null);
    const onImgLoad = ({ target: img }) => {
      const { offsetHeight, offsetWidth } = img;
      setWidth(offsetWidth);
      // console.log("IMAGE SIZES:", offsetHeight, offsetWidth);
    };
    var w = Math.floor(Math.random() * 50 + 25).toString();
    return (
      <div
        key={index}
        style={{
          display: "flex",
          flexDirection: "column",
          // border: "1px solid var(--ion-color-primary)",
          minHeight: "250px",
          padding: "5px",
        }}
      >
        <div
          onClick={(e) => {
            var err = [];
            images
              ?.slice(index, index + 5)
              .map((item) => item.map((i) => err.push(i)));
            setRelatedImages(err);
            setImagesUrl(item);
            setClickedpicture(item);
            console.log("slpit", images?.slice(index, index + 3));
            // console.log("imgsArr:",imgsArr)
            setImagesShow(true);
          }}
          // className="card-image"
          style={{ position: "relative" }}
        >
          <img
            src={item.url ? item.url : item.qualityUrl}
            alt=""
            width={"100%"}
            height="200px"
            onLoad={onImgLoad}
          />
          <div
            style={{
              position: "absolute",
              backgroundColor: "lightgrey",
              top: 0,
              padding: 3,
            }}
          >
            {item.width} x {item.height}
          </div>
          <span
            style={{
              padding: "15px",
              fontSize: "14px",
              color: "var(--dark)",
              width: width,
              width: "100%",
            }}
          >
            {item.title ? item.title : "No title"}
            <br />
            {/* {item.sourceUrl ? item.sourceUrl : "No source"} */}
          </span>
        </div>
      </div>
    );
  }

  const fetchMorePictures = () => {
    setIsLoading(true);
    var dat = GetFullPath();

    $.get(
      `https://platinovida.pythonanywhere.com/telzho/bing-pictures/${dat["query"]}/${scrollCounter}`,
      (data) => {
        // setImages(data.data.filter(d => !d.url.includes( "online.healingstreams.tv" ) ));
        var da = images
          .concat(data)
          .filter((ee, i) => filtersControl(dat["query"]));
        setImages(da);

        // setImages(images.sort())
        setScrollCounter(scrollCounter + 36);

        setSuggestion([]);
        setIsLoading(false);
      }
    );
  };

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 1) {
        fetchMorePictures();
      }
    }
  };

  const chunks = (xs, [s, ...ss]) =>
    xs.length ? [xs.slice(0, s), ...chunks(xs.slice(s), [...ss, s])] : [];

  function getChuck(arr, size) {
    const xl = [];
    const lg = [4, 5, 4, 4, 5, 5, 4];
    const md = [3, 3, 4, 4, 3];
    const sm = [2, 2, 1, 1, 2, 1, 2];
    const xm = [2, 1, 2, 1, 2, 2, 1];
    if (size > 900) {
      var res = chunks(arr, lg);
      setImages(res);
      return true;
    }
    if (size > 700) {
      var res = chunks(arr, md);
      setImages(res);
      return true;
    }
    if (size > 380) {
      var res = chunks(arr, sm);
      setImages(res);
      return true;
    } else {
      var res = chunks(arr, xm);
      setImages(res);
      return true;
    }

    // setImages(images.sort())
  }

  useEffect(() => {
    setTimeout(() => {
      setSuggestion([]);
    }, 1000);
    var dat = GetFullPath();

    var start = scrollCounter - 10;
    var end = scrollCounter;

    $.get(
      `https://platinovida.pythonanywhere.com/telzho/images/${dat["query"]}`,
      (data) => {
        // setImages(data.data.filter(d => !d.url.includes( "online.healingstreams.tv" ) ));
        var da = images
          .concat(data)
          .filter((ee, i) => filtersControl(dat["query"]));

        //console.log(data);
        if (da?.length == 0) {
          setHaveData(true);
        } else {
          setHaveData(false);
        }
        setImagesStack(da);
        var width = $(window).width();
        getChuck(da, width);

        setSuggestion([]);
      }
    );

    let timeout;
    const handleResize = (e) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        var width = $(window).width();
        getChuck(imagesStack, width);
      }, 200);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const slideTo = (index) => {
    sliderRef.current.slideTo(index);
  };

  const onSlideChange = () => {
    console.log("onSlideChange");
    console.log("relayed images:", relatedImages);

    sliderRef.current.getActiveIndex().then((index) => {
      setImagesUrl(index === 0 ? clickedpicture : relatedImages[index - 1]);
    });
  };

  return (
    <IonPage>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
      ></link>
      <HomeNavbar />
      <IonContent
        slot="fixed"
        fullscreen
        // onIonScrollEnd={fetchMorePictures}
        // ref={listInnerRef}
        // overflowY="scroll"
        style={{ width: "100%" }}
      >
        <div
          // onScroll={onScroll}
          ref={listInnerRef}
          style={{ height: "100%", overflowY: "scroll" }}
        >
          {imageShow == true ? (
            <div className="pop-up">
              <div className="pop-up-close-container">
                <div
                  className="pop-up-close"
                  onClick={(e) => setImagesShow(false)}
                >
                  <i className="fa fa-times" aria-hidden="true"></i>
                </div>
              </div>

              <div className="pop-up-image-container">
                <button
                  className="swipe-buttons"
                  onClick={() => {
                    sliderRef.current.slidePrev();
                  }}
                >
                  <IonIcon
                    style={{ fontSize: 30 }}
                    icon={arrowDropleftCircle}
                    size={50}
                  />
                </button>
                <IonSlides
                  ref={sliderRef}
                  onIonSlideWillChange={() => onSlideChange()}
                >
                  <IonSlide>
                    <img
                      src={
                        imageUrl?.qualityUrl
                          ? "http://" + imageUrl?.qualityUrl
                          : imageUrl?.url
                      }
                      alt=""
                      className="pop-up-image"
                    />
                  </IonSlide>

                  {relatedImages?.map((pic, index) => (
                    <IonSlide key={index}>
                      <img
                        src={
                          pic?.qualityUrl ? "http://" + pic.qualityUrl : pic.url
                        }
                        alt=""
                        className="pop-up-image"
                      />
                    </IonSlide>
                  ))}
                </IonSlides>
                <button
                  onClick={() => {
                    sliderRef.current.slideNext();
                  }}
                  className="swipe-buttons"
                >
                  <IonIcon
                    style={{ fontSize: 30 }}
                    icon={arrowDroprightCircle}
                    size={100}
                  />
                </button>
              </div>
              {/* related images */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  overflowX: "auto",
                  height: 150,
                  width: "70%",
                  margin: "auto",
                }}
              >
                {relatedImages?.map((pic, index) => {
                  return (
                    <img
                      key={index}
                      src={pic.url ? pic.url : "http://" + pic.qualityUrl}
                      alt=""
                      className="pop-up-image-down"
                      onClick={() => slideTo(index + 1)}
                    />
                  );
                })}
              </div>

              <div className="pop-up-footer">
                <div className="left-footer">
                  <a
                    href={imageUrl?.sourceUrl}
                    className="pop-up-footer-heading"
                  >
                    {imageUrl?.title?.slice(0, 35)}...
                  </a>
                  <div className="pop-up-footer-text">
                    {imageUrl?.width} x {imageUrl?.height}
                  </div>
                </div>
                <IonButton href={imageUrl?.sourceUrl} class="pop-up-btn">
                  View file
                </IonButton>
              </div>
            </div>
          ) : (
            ""
          )}

          <PopOver />
          <br />
          <InputSearch
            setSuggestion={setSuggestion}
            setExternalInputText={setInputText}
          />
          <ion-icon name="logo-twitter"></ion-icon>
          <Suggestions getSuggestion={getSuggestion} path="images" />
          <LinksTab />

          {images.length == 0 && HaveData == true ? (
            <div>
              <br />
              <IonText>
                No Results, Make sure that all words are spelled correctly
              </IonText>
            </div>
          ) : (
            ""
          )}

          {images.length == 0 && HaveData != true ? (
            <IonRow>
              {fakeData.map((item, index) => (
                <IonCol
                  sizeSm="6"
                  sizeMd="4"
                  sizeLg="4"
                  sizeXl="3"
                  sizeXs="12"
                  key={index}
                >
                  <IonCard>
                    <IonLabel>
                      <h3>
                        <IonSkeletonText animated style={{ width: "50%" }} />
                      </h3>
                      <p>
                        <IonSkeletonText animated style={{ width: "100%" }} />
                      </p>
                      <p>
                        <IonSkeletonText animated style={{ width: "80%" }} />
                      </p>
                      <p>
                        <IonSkeletonText animated style={{ width: "60%" }} />
                      </p>
                    </IonLabel>
                  </IonCard>
                </IonCol>
              ))}
            </IonRow>
          ) : (
            ""
          )}
          {/* Container for images */}

          {images?.map((item, index) => {
            {
              JSON.stringify(item);
            }
            return (
              <div
                className="image-row"
                style={{ marginLeft: 10, marginRight: 10 }}
              >
                {item.map((i, index1) => (
                  <ImageContainer item={i} index={index} index1={index1} />
                ))}
              </div>
            );
          })}

          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              display: isLoading ? "flex" : "none",
            }}
          >
            <ThreeDots
              height="50"
              width="50"
              color="green"
              ariaLabel="loading"
            />
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}

import React, { useEffect } from "react";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "./vendor";
import "./css/scroll.css";

import HomePage from "./Pages/HomePage";
import SettingsPage from "./Pages/SettingsPage";
import AllPage from "./Pages/AllPage";
import NewsPage from "./Pages/NewsPage";
import PicturesPage from "./Pages/PicturesPage";
import VideosPage from "./Pages/VideosPage";
// import { AmplifyProvider } from "@aws-amplify/ui-react";

const App = () => {
  useEffect(() => {
    if (localStorage.getItem("theme") === null) {
      var theme = localStorage.setItem("theme", "dark");
      document.body.classList.add("dark");
    } else {
      var theme = localStorage.getItem("theme");
      document.body.classList.add(theme);
    }
  }, []);

  return (
    // <AmplifyProvider>
      <Router>
        <Switch>
          <Route key={"route1"} exact path="/">
            <HomePage />
          </Route>
          <Route key={"route2"} path="/settings">
            <SettingsPage />
          </Route>

          <Route key={"route3"} path="/all">
            <AllPage />
          </Route>
          <Route key={"route4"} path="/images">
            <PicturesPage />
          </Route>

          <Route key={"route5"} path="/videos">
            <VideosPage />
          </Route>
          <Route key={"route6"} path="/news">
            <NewsPage />
          </Route>

          <Route key={"route7"} path="*">
            <h1>not dound</h1>
          </Route>
        </Switch>
      </Router>
    // </AmplifyProvider>
  );
};

export default App;

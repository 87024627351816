import React from 'react'

export default function filtersControl(ee) {
  return  !ee.toLowerCase().includes("cnn".toLowerCase())&&
  !ee.toLowerCase().includes("Centers for Disease Control".toLowerCase())&&
  !ee.toLowerCase().includes("cdc".toLowerCase())&&
  !ee.toLowerCase().includes("John Hopkins University".toLowerCase())&&
  !ee.toLowerCase().includes("Hopkins University".toLowerCase())&&
  !ee.toLowerCase().includes("John Hopkins".toLowerCase())&&
  !ee.toLowerCase().includes("John University".toLowerCase())&&
  !ee.toLowerCase().includes("uk news".toLowerCase())&&
  !ee.toLowerCase().includes("Cable News Network".toLowerCase())&&
  !ee.toLowerCase().includes("Cable News".toLowerCase())&&
  !ee.toLowerCase().includes("Cable Network".toLowerCase())&&
  !ee.toLowerCase().includes("bbc".toLowerCase())&&
  !ee.toLowerCase().includes("South African New".toLowerCase())&&
  !ee.toLowerCase().includes("zim New".toLowerCase())&&
  !ee.toLowerCase().includes("Zimbabwe new".toLowerCase())&&
  !ee.toLowerCase().includes("Aljazira".toLowerCase())
}

/* eslint-disable jsx-a11y/iframe-has-title */
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonLoading,
  IonRow,
  IonSkeletonText,
  IonAvatar,
  IonLabel,
  IonList,
  IonListHeader,
  IonItem,
  IonThumbnail,
  IonSpinner,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import $ from "jquery";
import "../css/home-cards.css";

export default function HomeCards() {
  var fakeData = [];
  for (let index = 0; index < 10; index++) {
    fakeData.push(index);
  }
  var [news, setNews] = useState([]);
  const [showLoading, setShowLoading] = useState(true);

  var [themeActive, setThemeActive] = useState(null);
  useEffect(() => {
    if (localStorage.getItem("theme") !== null) {
      var theme = localStorage.getItem("theme");
      setThemeActive(theme);
    } else {
      setThemeActive("light");
    }
    var service2 = "https://telzho-apis.000webhostapp.com/api/ln24.php";
    var service1 =
      "https://platinovida.pythonanywhere.com/telzho/ln24sa/world/";
    $.get(service2, (data) => {
      setNews(data);
      // console.log(data)
    })
      .done(function (data) {
        console.log("Data from API:", data);
        setShowLoading(false);
      })
      .catch((e) => setShowLoading(false));
  }, []);

  return (
    <>
      {/* <IonLoading
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={'Loading Content'}
            duration={5000}
        /> */}

      <div className="spinnerParent">
        {showLoading && <IonSpinner color="primary"></IonSpinner>}
      </div>

      {news.length == 0 ? (
        <IonRow
          class="home-card"
          style={{
            flexWrap: "nowrap",
            overflowX: "scroll!important",
            overflowY: "hidden",
          }}
        >
          {fakeData.map((item, index) => (
            <IonCol
              sizeSm="6"
              sizeMd="4"
              sizeLg="4"
              sizeXl="3"
              sizeXs="12"
              key={index}
            >
              <IonCard>
                <IonSkeletonText
                  animated
                  style={{ width: "100%", height: "150px" }}
                />
                <IonLabel>
                  <h3>
                    <IonSkeletonText animated style={{ width: "50%" }} />
                  </h3>
                  <p>
                    <IonSkeletonText animated style={{ width: "100%" }} />
                  </p>
                  <p>
                    <IonSkeletonText animated style={{ width: "80%" }} />
                  </p>
                  <p>
                    <IonSkeletonText animated style={{ width: "60%" }} />
                  </p>
                </IonLabel>
              </IonCard>
            </IonCol>
          ))}
        </IonRow>
      ) : (
        ""
      )}

      <IonRow
        class="home-card"
        style={{
          flexWrap: "nowrap",
          overflowX: "scroll!important",
          overflowY: "hidden",
        }}
      >
        {news.slice(0, 8).map((item, index) => (
          <IonCol
            sizeSm="6"
            sizeMd="4"
            sizeLg="4"
            sizeXl="3"
            sizeXs="10"
            key={index}
          >
            <IonCard href={item.url}>
              {/* <IonCardHeader> */}
              <h6>{item.title.substring(0, 60)}...</h6>
              {/* </IonCardHeader> */}

              <img className="homeNewImg" src={item.imageUrl} alt="" />

              <IonCardContent>
                {item.content.substring(0, 100)}...
              </IonCardContent>
            </IonCard>
          </IonCol>
        ))}
      </IonRow>

      {news.length > 0 ? (
        <div className="forexNewsContainer">
          <div className="forexNews">
            {themeActive !== null ? (
              <>
                <br />
                <br />

                <iframe
                  src={`https://fxpricing.com/fx-widget/forex-cross-rates.php?symbol=EUR,USD,ZAR&click_target=blank&theme=${themeActive}&&hr-cr=00000013&flags=circle&font=Arial,%20sans-serif`}
                  width="100%"
                  height="200"
                  frameBorder="0"
                  marginWidth="0"
                  marginHeight="0"
                  style={{ borderRadius: "3px" }}
                ></iframe>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

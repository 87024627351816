import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonSkeletonText,
  IonText,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import HomeNavbar from "../components/HomeNavbar";
import PopOver from "../components/FloatingButton";

import "../css/icons-list.css";
import InputSearch from "../components/InputSearch";
import $ from "jquery";
import GetFullPath from "../components/GetFullPath";
import { book, image, search, videocam } from "ionicons/icons";
import LinksTab from "../components/LinksTab";
import { useHistory } from "react-router";
import Suggestions from "../components/Suggestions";
import filtersControl from "../components/filtersControl";
export default function () {
  const [news, setNews] = useState([]);
  var [getSuggestion, setSuggestion] = useState([]);
  var [inputText, setInputText] = useState("");
  var [HaveData, setHaveData] = useState(false);

  var fakeData = [];
  for (let index = 0; index < 10; index++) {
    fakeData.push(index);
  }

  useEffect(() => {
    setTimeout(() => {
      setSuggestion([]);
    }, 1000);
    var dat = GetFullPath();

    $.get(
      `https://platinovida.pythonanywhere.com/telzho/bing-news/${dat["query"]}`,
      (data) => {
        if (data.length > 0) {
          var da = data.filter((ee, i) => filtersControl(dat["query"]));
          setNews(da);
          if (da?.length === 0) {
            setHaveData(true);
          } else {
            setHaveData(false);
          }
        } else {
          setHaveData(true);
        }
        console.log("news24:", data);
        setSuggestion([]);
      }
    );
  }, []);

  return (
    <>
      <IonPage>
        <HomeNavbar />
        <IonContent slot="fixed" fullscreen>
          <PopOver />
          <br />
          <InputSearch
            setSuggestion={setSuggestion}
            setExternalInputText={setInputText}
          />
          <Suggestions getSuggestion={getSuggestion} path="news" />
          <LinksTab />

          {news.length == 0 && HaveData == true ? (
            <div>
              <br />
              <IonText>
                No Results, Make sure that all words are spelled correctly
              </IonText>
            </div>
          ) : (
            ""
          )}

          {news.length == 0 && HaveData != true ? (
            <IonRow>
              {fakeData.map((item, index) => (
                <IonCol
                  sizeSm="6"
                  sizeMd="4"
                  sizeLg="4"
                  sizeXl="3"
                  sizeXs="12"
                  key={index}
                >
                  <IonCard>
                    <IonLabel>
                      <h3>
                        <IonSkeletonText animated style={{ width: "50%" }} />
                      </h3>
                      <p>
                        <IonSkeletonText animated style={{ width: "100%" }} />
                      </p>
                      <p>
                        <IonSkeletonText animated style={{ width: "80%" }} />
                      </p>
                      <p>
                        <IonSkeletonText animated style={{ width: "60%" }} />
                      </p>
                    </IonLabel>
                  </IonCard>
                </IonCol>
              ))}
            </IonRow>
          ) : (
            ""
          )}

          <IonRow>
            {news.slice(0, 8).map((item, index) => (
              <IonCol
                sizeSm="6"
                sizeMd="4"
                sizeLg="4"
                sizeXl="3"
                sizeXs="12"
                key={index}
              >
                <IonCard href={item.Url}>
                  <img
                    src={item.ImgUrl}
                    alt=""
                    style={{
                      height: "180px",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                  <IonCardHeader>
                    <IonCardTitle
                      style={{ color: "var(--ion-color-very-dark)" }}
                    >
                      <b>{item.Title?.substring(0, 60)}...</b>
                    </IonCardTitle>
                  </IonCardHeader>

                  <IonCardContent>
                    {item.Time?.substring(0, 100)}
                  </IonCardContent>
                </IonCard>
              </IonCol>
            ))}
          </IonRow>
        </IonContent>

        <br />
        <br />
        <br />
      </IonPage>
    </>
  );
}

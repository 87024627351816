import { IonIcon, IonText } from '@ionic/react'
import { book, image, search, videocam } from 'ionicons/icons'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import "../css/links.css"
import GetFullPath from './GetFullPath'
export default function LinksTab() {
    var [active, setActive] = useState("")
    var data = GetFullPath();
    useEffect(() => {
        setActive(data["path"])
        console.log(data)
    }, [])
    return (
        <div className="links">
            <Link to={`/all?page=0&query=${data["query"]}`} className={active == "/all" ? 'active' : ""}>
                <IonText >
                    <IonIcon icon={search} className="iconLinkTab"/>All
                </IonText>
            </Link>
            <Link  to={`/images?page=0&query=${data["query"]}`} className={active == "/images" ? 'active' : ""}>
                <IonText>
                    <IonIcon icon={image}  className="iconLinkTab"/>images
                </IonText>
            </Link>
            <Link  to={`/videos?page=0&query=${data["query"]}`} className={active == "/videos" ? 'active' : ""}>
                <IonText>
                    <IonIcon icon={videocam} className="iconLinkTab"/>videos
                </IonText>
            </Link>
            <Link   to={`/news?page=0&query=${data["query"]}`} className={active == "/news" ? 'active' : ""}>
                <IonText >
                    <IonIcon icon={book} className="iconLinkTab"/>news
                </IonText>
            </Link>
        </div>
    )
}

import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonSkeletonText,
  IonText,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import HomeNavbar from "../components/HomeNavbar";
import PopOver from "../components/FloatingButton";

import "../css/icons-list.css";

import { AiOutlinePlayCircle } from "react-icons/ai";
import InputSearch from "../components/InputSearch";
import $ from "jquery";
import GetFullPath from "../components/GetFullPath";
import { book, image, search, videocam } from "ionicons/icons";
import LinksTab from "../components/LinksTab";
import Suggestions from "../components/Suggestions";
import filtersControl from "../components/filtersControl";
export default function () {
  const [images, setImages] = useState([]);
  var [getSuggestion, setSuggestion] = useState([]);
  var [inputText, setInputText] = useState("");
  var [scrollCounter, setScrollCounter] = useState(20);
  var [HaveData, setHaveData] = useState(false);

  var fakeData = [];
  for (let index = 0; index < 10; index++) {
    fakeData.push(index);
  }

  const fetchMorePictures = () => {
    var data = GetFullPath();

    var start = scrollCounter - 10;
    var end = scrollCounter;
    setScrollCounter(scrollCounter + 10);
    console.log("start: " + start + " end: " + end);

    $.get(
      `https://platinovida.pythonanywhere.com/telzho/videos/${data["query"]}/${start}/${end}`,
      (data) => {
        // setImages(data.data.filter(d => !d.url.includes( "online.healingstreams.tv" ) ));
        setImages(images.concat(data));
        console.log("videos found:", images);
        // setImages(images.sort())

        setSuggestion([]);
      }
    );
  };

  useEffect(() => {
    setTimeout(() => {
      setSuggestion([]);
    }, 1000);
    var data = GetFullPath();
    var start = scrollCounter - 10;
    var end = scrollCounter;
    console.log("start: " + start + " end: " + end);
    $.get(
      `https://platinovida.pythonanywhere.com/telzho/bing-videos/${data["query"]}/${start}/${end}`,
      (data) => {
        // setImages(data.data.filter(d => !d.url.includes( "online.healingstreams.tv" ) ));
        setImages(data);
        console.log("videos here:", data);
        setSuggestion([]);
      }
    );
  }, []);

  return (
    <>
      <IonPage>
        <HomeNavbar />
        <IonContent slot="fixed" fullscreen onIonScroll={fetchMorePictures}>
          <PopOver />
          <br />
          <InputSearch
            setSuggestion={setSuggestion}
            setExternalInputText={setInputText}
          />
          <Suggestions getSuggestion={getSuggestion} path="videos" />
          <LinksTab />
          {images.length == 0 && HaveData == true ? (
            <div>
              <br />
              <IonText>
                No Results, Make sure that all words are spelled correctly
              </IonText>
            </div>
          ) : (
            ""
          )}

          {images.length == 0 && HaveData != true ? (
            <IonRow>
              {fakeData.map((item, index) => (
                <IonCol
                  sizeSm="6"
                  sizeMd="4"
                  sizeLg="4"
                  sizeXl="3"
                  sizeXs="12"
                  key={index}
                >
                  <IonCard>
                    <IonLabel>
                      <h3>
                        <IonSkeletonText animated style={{ width: "50%" }} />
                      </h3>
                      <p>
                        <IonSkeletonText animated style={{ width: "100%" }} />
                      </p>
                      <p>
                        <IonSkeletonText animated style={{ width: "80%" }} />
                      </p>
                      <p>
                        <IonSkeletonText animated style={{ width: "60%" }} />
                      </p>
                    </IonLabel>
                  </IonCard>
                </IonCol>
              ))}
            </IonRow>
          ) : (
            ""
          )}

          <IonRow>
            {images?.map((item, index) => (
              <IonCol
                sizeSm="6"
                sizeMd="4"
                sizeLg="4"
                sizeXl="3"
                sizeXs="12"
                key={index}
              >
                <IonCard href={item?.url} className="videoCards">
                  <IonCardContent
                    style={{ width: "auto", height: "200px", padding: 0 }}
                  >
                    <span
                      style={{ position: "absolute", top: "45%", left: "45%" }}
                    >
                      <AiOutlinePlayCircle
                        className="icon"
                        size={50}
                        color="#26badb"
                      />
                    </span>
                    <img
                      src={item.picture}
                      alt=""
                      style={{ width: "100%", height: "100%" }}
                    />
                  </IonCardContent>
                  <IonCardTitle
                    style={{
                      padding: "10px",
                      fontSize: "14px",
                      color: "var(--dark)",
                    }}
                  >
                    {item.title.substring(0, 60)}...
                  </IonCardTitle>
                </IonCard>
              </IonCol>
            ))}
          </IonRow>
        </IonContent>

        <br />
        <br />
        <br />
      </IonPage>
    </>
  );
}

import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonSkeletonText,
  IonText,
  IonTitle,
  ionToolbar,
  ionTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import HomeNavbar from "../components/HomeNavbar";
import PopOver from "../components/FloatingButton";

import "../css/icons-list.css";
import InputSearch from "../components/InputSearch";
import $ from "jquery";
import GetFullPath from "../components/GetFullPath";
import {
  arrowBack,
  arrowForward,
  book,
  image,
  logoWindows,
  search,
  videocam,
} from "ionicons/icons";
import LinksTab from "../components/LinksTab";
import { useHistory } from "react-router";

import "../css/allPage.css";

const PictureSlide = ({ stateList }) => {
  return (
    <>
      {stateList?.length > 0 ? (
        <h5 style={{ boxShadow: "none" }}>
          <div style={{ boxShadow: "none" }}>Related Images</div>
        </h5>
      ) : (
        ""
      )}
      <IonRow
        style={{
          flexWrap: "nowrap",
          overflowX: "scroll!important",
          overflowY: "hidden",
        }}
      >
        {stateList.slice(0, 5)?.map((img, index) => (
          <IonCol
            sizeSm="8"
            sizeMd="5"
            sizeLg="5"
            sizeXl="4"
            sizeXs="8"
            key={index + 99}
          >
            <IonCard
              style={{ width: "100%", height: "auto" }}
              href={img.imageUrl}
            >
              <img
                onError={(event) => (event.target.style.display = "none")}
                src={img.url}
                alt=""
                style={{
                  "object-fit": "cover",
                  width: "300px",
                  height: "150px",
                }}
              />
            </IonCard>
          </IonCol>
        ))}
      </IonRow>
    </>
  );
};

export default PictureSlide;

/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-unused-vars */
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonSkeletonText,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import HomeNavbar from "../components/HomeNavbar";
import PopOver from "../components/FloatingButton";
import Map from "../Map";
import "../css/icons-list.css";
import InputSearch from "../components/InputSearch";
import $ from "jquery";
import GetFullPath from "../components/GetFullPath";
import {
  arrowBack,
  arrowForward,
  book,
  image,
  logoWindows,
  search,
  videocam,
} from "ionicons/icons";
import LinksTab from "../components/LinksTab";
import { useHistory } from "react-router";
import PictureSlide from "../components/pictureSlide";
import VideoSlide from "../components/videosDisplay";
import { compass, logoChrome } from "ionicons/icons";

import "../css/allPage.css";
import Suggestions from "../components/Suggestions";
import { set } from "express/lib/application";
import filtersControl from "../components/filtersControl";

export default function () {
  const [news, setNews] = useState([]);
  var [getSuggestion, setSuggestion] = useState([]);

  var [sideSuggestions, setSideSuggestions] = useState([]); //for side suggestions
  var [sideSuggestionsImages, setSideSuggestionsImages] = useState([]);

  var [inputText, setInputText] = useState("");

  var [relatedImages, setRelatedImages] = useState([]);
  var [relatedVideos, setRelatedVideos] = useState([]);

  var fakeData = [];
  for (let index = 0; index < 10; index++) {
    fakeData.push(index);
  }
  var [page, setPage] = useState(0);
  var [query, setQuery] = useState("");
  var history = useHistory();
  var [HaveData, setHaveData] = useState(false);

  const getImages = () => {
    var dat = GetFullPath();
    var count = (parseInt(dat["page"]) + 1) * 15;
    console.log("image counter", count);
    $.get(
      `https://platinovida.pythonanywhere.com/telzho/images/${dat["query"]}`,
      (data) => {
        var resultsLen = data.length;
        var start = count - 15;
        var end = count;
        console.log("Image slice:", start, end);
        var img = data
          .slice(start, end)
          .filter((ee, i) => filtersControl(dat["query"]));
        setRelatedImages(img);
        setSuggestion([]);
      }
    );
  };

  const getVideos = () => {
    var data = GetFullPath();
    var count = (parseInt(data["page"]) + 1) * 15;
    console.log("image counter", count);
    $.get(
      `https://platinovida.pythonanywhere.com/telzho/bing-videos/${data["query"]}/0/${count}`,
      (data) => {
        var resultsLen = data.length;
        var start = resultsLen - 15;
        var end = resultsLen;
        setRelatedVideos(data.slice(start, end));
        console.log("sliding vids:", data.slice(start, end));
      }
    );
  };

  function splitter(data = "") {
    const isUpperCase = (string) => /^[A-Z]*$/.test(string);
    var l = data.length;
    var str = "";
    var isUpper = false;
    for (var i = 0; i < l; i++) {
      var v = data[i];
      if (isUpperCase(v)) {
        isUpper = true;
      }
      if (isUpper === true) {
        str += v;
      }
    }
    return str;
  }

  useEffect(() => {
    // get url
    // counter()
    setTimeout(() => {
      setSuggestion([]);
    }, 1000);
    var dat = GetFullPath();
    setPage(parseInt(dat["page"]));
    setQuery(dat["query"]);
    setInputText(dat["query"]);

    $.get(
      `https://platinovida.pythonanywhere.com/telzho/all/${dat["page"]}/${dat["query"]}`,
      (data) => {
        // setNews(data.data.filter(d => !d.url.includes( "online.healingstreams.tv" ) ));

        console.log("data: ", data);
        console.log("serached data", data);

        if (data.data.length > 0) {
          var n = data.data.filter((ee, i) => filtersControl(dat["query"]));
          console.log(n);
          if (n?.length === 0) {
            setHaveData(true);
          } else {
            setHaveData(false);
          }
          setNews(n);
          var sugg = data["suggestionWords"].filter((ee, i) =>
            filtersControl(dat["query"])
          );
          var suggImage = data["suggestionImages"].filter((ee, i) =>
            filtersControl(dat["query"])
          );
          setSideSuggestions(sugg);
          setSideSuggestionsImages(suggImage);
        } else {
          setHaveData(true);
        }

        setSuggestion([]);
      }
    );
    getImages();
    getVideos();
  }, []);

  return (
    <>
      <IonPage>
        <HomeNavbar />
        <IonContent slot="fixed" fullscreen>
          <IonRow>
            <IonCol sizeSm="12" sizeMd="8" sizeLg="9" sizeXl="9" sizeXs="12">
              <div className="allPageContainer">
                <br />
                <InputSearch
                  setSideSuggestions={setSideSuggestions}
                  setSideSuggestionsImages={setSideSuggestionsImages}
                  //
                  setSuggestion={setSuggestion}
                  setExternalInputText={setInputText}
                  externalInputText={inputText}
                />
                <Suggestions getSuggestion={getSuggestion} path="all" />
                <LinksTab />
                {news.length === 0 && HaveData === true ? (
                  <div>
                    <br />
                    <IonText>
                      No Results, Make sure that all words are spelled correctly
                    </IonText>
                  </div>
                ) : (
                  ""
                )}
                {news.length === 0 && HaveData !== true ? (
                  <IonRow>
                    {fakeData.map((item, index) => (
                      <IonCol
                        sizeSm="6"
                        sizeMd="4"
                        sizeLg="4"
                        sizeXl="3"
                        sizeXs="12"
                        key={index}
                      >
                        <IonCard>
                          <IonLabel>
                            <h3>
                              <IonSkeletonText
                                animated
                                style={{ width: "50%" }}
                              />
                            </h3>
                            <p>
                              <IonSkeletonText
                                animated
                                style={{ width: "100%" }}
                              />
                            </p>
                            <p>
                              <IonSkeletonText
                                animated
                                style={{ width: "80%" }}
                              />
                            </p>
                            <p>
                              <IonSkeletonText
                                animated
                                style={{ width: "60%" }}
                              />
                            </p>
                          </IonLabel>
                        </IonCard>
                      </IonCol>
                    ))}
                  </IonRow>
                ) : (
                  ""
                )}
                {news?.length < 0 ? (
                  <h5 style={{ boxShadow: "none" }}>
                    <div style={{ boxShadow: "none" }}>Related Search</div>
                  </h5>
                ) : (
                  ""
                )}
                {/* Location results */}

                {query.length > 0 ? <Map searchQuery={query} /> : null}

                {/* <IonRow > */}
                {news?.map((item, index) => (
                  <>
                    {/* Related images (sliding images) */}
                    {index === 1 ? (
                      <PictureSlide stateList={relatedImages} />
                    ) : (
                      ""
                    )}
                    {/* Related images */}
                    {index === 3 ? (
                      <VideoSlide stateList={relatedVideos} />
                    ) : (
                      ""
                    )}

                    <IonCard href={item?.url} className="results_cards">
                      <IonCardHeader>
                        <IonCardTitle
                          style={{ color: "var(--ion-color-very-dark)" }}
                        >
                          <b> {splitter(item.title)}</b>
                        </IonCardTitle>
                      </IonCardHeader>

                      <IonCardContent>
                        {item.content.substring(0, 400)}...
                      </IonCardContent>

                      <IonText className="linkFotter">{item?.url}</IonText>
                    </IonCard>
                    {/* </IonCol> */}
                  </>
                ))}
                {/* </IonRow> */}
                <IonRow>
                  <IonCol
                    class="ion-hide-md-up"
                    sizeSm="12"
                    sizeXs="12"
                    sizeMd="4"
                    sizeLg="3"
                    sizeXl="3"
                  >
                    <div className="sideSuggestions">
                      <div className="suggestionContainer">
                        {sideSuggestions.length > 0 ? (
                          <h4 style={{ textAlign: "center" }}>Suggestions</h4>
                        ) : (
                          ""
                        )}

                        {sideSuggestions.map((suggestion, key) => {
                          return (
                            <div
                              key={key}
                              className="suggestedLinks"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <button
                                onClick={() => {
                                  window.location.href = `/all?query=${suggestion}&page=${(page += 1)}`;
                                }}
                              >
                                {suggestion}
                              </button>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </IonCol>
                </IonRow>
                <div style={{ margin: "auto", maxWidth: 100 }}>
                  <IonToolbar>
                    <IonButtons slot="start">
                      {page > 0 ? (
                        <IonButton
                          onClick={(e) => {
                            window.location.href = `/all?query=${query}&page=${(page -= 1)}`;
                          }}
                        >
                          <IonIcon slot="icon-only" icon={arrowBack} />
                        </IonButton>
                      ) : (
                        ""
                      )}
                      {news && news?.length >= 4 ? (
                        <IonButton
                          onClick={(e) => {
                            window.location.href = `/all?query=${query}&page=${(page += 1)}`;
                          }}
                        >
                          <IonIcon slot="icon-only" icon={arrowForward} />
                        </IonButton>
                      ) : (
                        ""
                      )}
                    </IonButtons>
                  </IonToolbar>
                </div>
              </div>
            </IonCol>
            {HaveData === false ? (
              <IonCol
                class="ion-hide-md-down"
                sizeSm="12"
                sizeXs="12"
                sizeMd="4"
                sizeLg="3"
                sizeXl="3"
              >
                <div className="sideSuggestions">
                  <div className="suggestionContainer">
                    {sideSuggestions.length > 0 ? <h4>Suggestions</h4> : ""}

                    {sideSuggestions.map((suggestion, key) => {
                      return (
                        <div key={key} className="suggestedLinks">
                          <button
                            onClick={() => {
                              window.location.href = `/all?query=${suggestion}&page=${(page += 1)}`;
                            }}
                          >
                            {suggestion}
                          </button>
                        </div>
                      );
                    })}
                  </div>

                  <div className="SuggestionsImagesContainer">
                    {relatedImages.length > 0 ? <h4>Media</h4> : ""}

                    <div className="sideImagesContainer">
                      {/* {console.log(relatedImages)} */}
                      {relatedImages.slice(6, 12).map((relatedImage, index) => {
                        return (
                          <div
                            className="relatedImage"
                            key={index + "relatedImage"}
                            onClick={() => {
                              window.location.href = `${relatedImage.qualityUrl}`;
                            }}
                          >
                            <img src={relatedImage.url} alt="relatedImage" />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </IonCol>
            ) : (
              ""
            )}
          </IonRow>
        </IonContent>
        <PopOver />
        <br /> <br /> <br />
      </IonPage>
    </>
  );
}

import React from "react";
import { IonFab, IonFabButton, IonIcon, IonFabList } from "@ionic/react";
import { settings,home, arrowUp } from "ionicons/icons";

export default function () {
  return (
    <IonFab  vertical="bottom" horizontal="start" slot="fixed">
      <IonFabButton className="FabButton" >
        <IonIcon icon={arrowUp} size="large"/>
      </IonFabButton>
      <IonFabList side="top">
        <IonFabButton href="/settings">
          <IonIcon icon={settings} />
        </IonFabButton>
        <IonFabButton href="/">
          <IonIcon icon={home} />
        </IonFabButton>
      </IonFabList>
    </IonFab>
  );
}

import {
  IonCard,

  IonCol,

  IonRow,

} from "@ionic/react";
import React, { useEffect, useState } from "react";
import {AiOutlinePlayCircle} from "react-icons/ai"
import "../css/videoDisplay.css"

const VideoSlide = ({stateList}) => {
    
    return(
        <>
        <IonRow
            style={{
            flexWrap: "nowrap",
            overflowX: "scroll!important",
            overflowY: "hidden",
            }}
        >
            {stateList?.map((vid, index) => (
                
            <IonCol
                sizeSm="8"
                sizeMd="5"
                sizeLg="5"
                sizeXl="4"
                sizeXs="8"
                key={index + 99}
            >
                
                <IonCard style={{width: "100%", height: "auto", position:"relative"}}>
                <img
                    onError={(event) =>
                    (event.target.style.display = "none")
                    }
                    src={vid.picture}
                    alt={vid.title}
                    style={{
                    "object-fit": "cover",
                    width: "450px",
                    height: "150px",
                    }}
                />
                <button className="playBtn"
                style={{position:"absolute", top:"40%", left:"40%", backgroundColor:"transparent"}}
                onClick={() => window.location.href = vid.url}
                >
                    <AiOutlinePlayCircle className="icon" size={50} color="#26badb" />
                </button>
                </IonCard>
            </IonCol>
            ))}
        </IonRow>
        </>
    )
}

export default VideoSlide;
import React, { useRef } from "react";
import {
    IonHeader,
    IonToolbar,
    IonIcon,

    IonMenuButton,
    IonMenu,
    IonList,
    IonItem,
    IonRouterOutlet, IonImg
} from "@ionic/react";

import Logo from '../asset/Logo.png'


import { home, settings } from 'ionicons/icons';

export default function () {
    const menuRef = useRef(null);
    return (
        <React.Fragment>
            <IonMenu type="overlay" menuId="first" contentId="myMenuOutlet" ref={menuRef} >


                <IonList>
                    <IonItem >
                        <IonImg style={{ width: "70px", paddingLeft: "10px" }} src={Logo} />

                    </IonItem>
                    <IonItem routerLink="/">
                        <IonIcon icon={home} slot="start" />
                        Home
                    </IonItem>
                    <IonItem routerLink="/settings">
                        <IonIcon icon={settings} slot="start" />
                        Settings
                    </IonItem>
                </IonList>

            </IonMenu>

            <IonRouterOutlet id="myMenuOutlet"></IonRouterOutlet>

            <IonHeader>
                <IonToolbar
                // color="transparent"
                >
                    <IonMenuButton slot="end" color="dark"></IonMenuButton>
                    <a href="/">
                        <IonImg style={{ width: "70px", paddingLeft: "10px" }} src={Logo} />
                    </a>
                </IonToolbar>
            </IonHeader>


        </React.Fragment>);
};


import $ from 'jquery'

export default function SuggestionService(urlKeywords) {
    let url = 'https://suggestqueries.google.com/complete/search?output=chrome&q=' + urlKeywords;
    // use JSONP (issue: http://security.stackexchange.com/questions/23438/security-risks-with-jsonp/23439#23439)
    // just for CORS trick
    return $.get({
        url: url,
        dataType: 'jsonp',
        type: 'GET',
    })
}
